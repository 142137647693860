$( document ).ready(function() {
    
	console.log( "ready!" );
	
	// Offcanvas Nav
	$(function () {
		$('[data-toggle="offcanvas"]').on('click', function () {
			$('.offcanvas-collapse').toggleClass('open')
		})
	});
	
});

// Animated ScrollTo Function: Use as element attribute. Ex: onClick="ascrollto('targetID');"
function ascrollto(id) {
	var etop = $('#' + id).offset().top;
	$('html, body').animate({
	  scrollTop: etop
	}, 300);
	
}